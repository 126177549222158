import { FC } from 'react'
import { SectionQuotesProps } from '@magal/models'
import { styled } from '@magal/styles'
import {
  Container,
  Media,
  ProgressSlider,
  SectionWrap,
} from '@magal/components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled(Container, {
  color: '$green_09',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$40',
  '@lg': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
})

const Sidebar = styled('div', {
  display: 'grid',
  gridTemplateRows: 'min-content',
  justifyContent: 'center',
  textAlign: 'center',
  maxWidth: '$maxWidthS',
  '@lg': {
    textAlign: 'left',
    justifyContent: 'initial',
  },
})

const Title = styled('h2', {
  projectFont: 'caps01',
})

const Subtitle = styled('p', {
  projectFont: 'body01',
  marginTop: '$20',
})

const Content = styled('div', {
  backgroundColor: '$gray300',
  borderRadius: '$r2',
  padding: '$28',
})

const ContentWrapper = styled('div', {
  margin: '0 auto',
  width: '100%',
  maxWidth: '415px',
  textAlign: 'center',
})

const Label = styled('p', {
  projectFont: 'caps07',
})

const QuoteWrapper = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto min-content',
  textAlign: 'center',
  justifyContent: 'center',
  gridRow: 1,
  gridColumn: 1,
  alignItems: 'center',
  transition: 'opacity 400ms',
  variants: {
    isActive: {
      true: {
        opacity: 1,
        zIndex: 9,
      },
      false: {
        opacity: 0,
        zIndex: 1,
      },
    },
  },
})

const Quote = styled('p', {
  projectFont: 'caps02',
  marginTop: '$96',
  marginBottom: '$72',
})

const IconWrapper = styled('div', {
  margin: '0 auto',
  width: '100%',
  maxWidth: '100px',
  display: 'block',
  '@lg': {
    maxWidth: '120px',
  },
})

export const SectionQuotes: FC<SectionQuotesProps> = ({
  title,
  subtitle,
  quotes,
}) => {
  const { t } = useTranslation('sectionQuotes')

  const slides = quotes?.map(({ quote, icon }, index) => {
    return (
      <QuoteWrapper key={`${quote}-${index}`}>
        <Quote>{quote}</Quote>
        {icon && (
          <IconWrapper>
            <Media {...icon} />
          </IconWrapper>
        )}
      </QuoteWrapper>
    )
  })

  return (
    <SectionWrap type={'BORDERS'}>
      <Wrapper>
        <Sidebar>
          {title && <Title>{title}</Title>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Sidebar>
        <Content>
          <ContentWrapper>
            <Label>{t('quotes')}</Label>
            {slides && (
              <ProgressSlider
                slides={slides}
                indicatorTheme={'dark'}
              ></ProgressSlider>
            )}
          </ContentWrapper>
        </Content>
      </Wrapper>
    </SectionWrap>
  )
}
